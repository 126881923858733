import React from "react";
import { View, StyleSheet } from "react-native";
import { Spacing, Colors } from "shared/utils/theme";
import { Footer, StandaloneFooter } from "shared/components/web/Footer";
import { Header, StandaloneHeader } from "shared/components/web/Header";
import Head from "next/head";

const styles = StyleSheet.create({
  page: {
    backgroundColor: Colors.background,
    alignItems: "center",
    flex: 1,
    minHeight: "100vh",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  outer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    flex: 1,
  },
  contentWrapperNarrow: {
    maxWidth: "min(100vw, 632px)",
    width: "100%",
    paddingHorizontal: Spacing.between,
    flex: 1,
  },
  contentWrapperWide: {
    maxWidth: `min(100vw, ${Spacing.webContentWidth}px)`,
    width: "100%",
    paddingHorizontal: Spacing.between,
    flex: 1,
  },
});

function ContentWrapper({ children, width = "narrow" }) {
  return (
    <View
      style={
        width === "narrow"
          ? styles.contentWrapperNarrow
          : styles.contentWrapperWide
      }
    >
      {children}
    </View>
  );
}

function PageWrapper({ children, standalone = false, title = "KULTA" }) {
  return (
    <View style={styles.page}>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <link rel="apple-touch-icon" href="/logo512.png" />
      </Head>
      <Header minimal={standalone} />
      <View style={styles.outer}>{children}</View>
      {standalone ? <StandaloneFooter /> : <Footer />}
    </View>
  );
}

export { PageWrapper, ContentWrapper };
