import React, { useRef } from "react";
import { View } from "react-native";
import { Text } from "shared/components/Text";
import Flatpickr from "react-flatpickr";
import { DateRangePickerProps } from "./types";
import "./theme.css";
import { Touchable } from "shared/components/Touchable";
import { Icon } from "shared/components/Icon";
import { Colors } from "shared/utils/theme";
import { German } from "flatpickr/dist/l10n/de";

function DateRangePicker(props: DateRangePickerProps) {
  const fp = useRef(null);
  const color = props.color || Colors.primary;
  return (
    <View style={{ flexDirection: "row" }}>
      <Touchable
        style={{ flexDirection: "row", alignItems: "center" }}
        onPress={() => {
          if (!fp?.current?.flatpickr) return;
          fp.current.flatpickr.toggle();
        }}
      >
        <View
          style={{
            width: 40,
            height: 32,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon name="f-calendar" size={24} color={color} />
          <Icon name="f-chevron-down" size={18} color={color} />
        </View>
      </Touchable>
      <Flatpickr
        style={{ opacity: 0, width: 0, marginLeft: 0 }}
        ref={fp}
        value={props.fromDate && [props.fromDate, props.toDate]}
        options={{
          minDate: "today",
          position: "below center",
          locale: German,
          disableMobile: true,
          mode: "range",
          monthSelectorType: "static",
        }}
        onChange={([from, to]) => {
          if (from && to) {
            props.onPicked(from, to);
          }
        }}
      />
      <View style={{ width: 28 }} />
    </View>
  );
}

export { DateRangePicker };
