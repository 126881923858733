import React from "react";
import { View } from "react-native";

import { Spacing, Gradients } from "shared/utils/theme";
import { Tile } from "./Tile.web";
import { CategoryMenuProps } from "./types";

const CategoryMenu = React.memo<CategoryMenuProps>(
  ({ current, onHover, hideCategories = [], onPicked, eventTypes }) => {
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {!hideCategories.includes("all") && (
          <View style={{ marginBottom: Spacing.betweenHalf }}>
            <Tile
              gradient={Gradients.apple[11]}
              onHoverIn={() => onHover && onHover("all")}
              label="Alle Events"
              icon="f-hexagon"
              active={current === "all"}
              onPress={() => onPicked && onPicked("all")}
            />
          </View>
        )}
        {eventTypes
          .filter(
            ({ eventType, hidden }) =>
              !hideCategories.includes(eventType) || !hidden
          )
          .sort((a, b) => a.sort_index - b.sort_index)
          .map((category) => {
            const active = category.eventType === current;
            return (
              <View
                key={category.eventType}
                style={{ marginBottom: Spacing.between }}
              >
                <Tile
                  onHoverIn={() => onHover && onHover(category.eventType)}
                  active={active}
                  gradient={Gradients.apple[category.gradientIndex]}
                  label={category.shortLabel}
                  icon={category.icon}
                  onPress={() => onPicked && onPicked(category.eventType)}
                />
              </View>
            );
          })}
      </View>
    );
  }
);

export { CategoryMenu };
