import React from "react";
import { Pressable, Platform, View } from "react-native";

import { Spacing, Colors, Radius, Gradients } from "shared/utils/theme";
import { Touchable } from "shared/components/Touchable";
import { Icon } from "shared/components/Icon";
import { LinearGradient } from "shared/components/Gradient";
import { Text } from "shared/components/Text";

const spacing = Spacing.betweenHalf + 4;

function Tile({
  gradient = Gradients.other.black,
  icon,
  label = "",
  onPress = () => {},
  onHoverIn = () => {},
  active = false,
  compact = false,
}) {
  return (
    <Touchable
      accessibilityRole="menuitem"
      accessibilityState={{ selected: active }}
      onPress={onPress}
      // @ts-ignore
      onHoverIn={onHoverIn}
      style={{
        flex: 1,
        marginRight: compact ? 8 : spacing,
        borderRadius: 100,
        overflow: "hidden",
      }}
    >
      <View
        style={{
          shadowColor: Colors.strongShadow,
          shadowOpacity: 0.7,
          shadowOffset: { width: 0, height: 0 },
          shadowRadius: 2,
          elevation: 2,
          marginHorizontal: 2,
          height: compact ? 40 : 48,
          borderRadius: Radius.medium,
          overflow: "hidden",
        }}
      >
        <LinearGradient
          start={{ x: 1, y: 0 }}
          end={{ x: 0, y: 1 }}
          colors={gradient}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 100,
          }}
        />
        {!active && (
          <View
            style={{
              backgroundColor: Colors.background,
              position: "absolute",
              top: compact ? 2 : 3,
              left: compact ? 2 : 3,
              bottom: compact ? 2 : 3,
              right: compact ? 2 : 3,
              borderRadius: 100,
            }}
          />
        )}
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            paddingVertical: Spacing.betweenHalf / 2,
            paddingHorizontal: compact ? 16 : Spacing.between + 4,
            alignItems: "center",
          }}
        >
          {icon && (
            <Icon
              name={icon}
              size={compact ? 14 : 20}
              color={active ? Colors.generic.white : Colors.primary}
              style={{ marginRight: compact ? 4 : 8 }}
            />
          )}
          <Text
            type={compact ? "captionStrong" : "button"}
            color={active ? Colors.generic.white : Colors.primary}
            style={{ flex: 1, textAlign: "center" }}
          >
            {label}
          </Text>
        </View>
      </View>
    </Touchable>
  );
}
export { Tile };
