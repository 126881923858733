import React, { useMemo } from "react";
import { View } from "react-native";
import { Text } from "shared/components/Text";
import { Colors, Spacing } from "shared/utils/theme";
import { CategoryMenu } from "shared/components/CategoryMenu";
import {
  format,
  addDays,
  nextSunday,
  isSunday,
  subDays,
  isSameDay,
  parse,
  isFriday,
  nextFriday,
  isSaturday,
} from "date-fns";
import { useRouter } from "next/router";
import { DateRangePicker } from "shared/components/DateRangePicker";
import { Link } from "shared/components/Link";
import { CalendarFilterProps } from "./types";

function doesMatch(path, fromDate, toDate = undefined) {
  const fromMatches = path.split("/")[3] === format(fromDate, "yyyy-MM-dd");
  const isOneDate =
    path.split("/").length === 4 || path.split("/")[3] === path.split("/")[4];
  const toMatches =
    isOneDate ||
    (toDate && path.split("/")[4] === format(toDate, "yyyy-MM-dd"));
  return fromMatches && toMatches;
}

function DatePresetButton({
  fromDate,
  toDate = undefined,
  replace = true,
  eventType,
  active,
  children,
}) {
  return (
    <Link
      replace={replace}
      resourceType="nextLink"
      resourceId={`/calendar/${eventType}/${format(fromDate, "yyyy-MM-dd")}${
        toDate ? "/" + format(toDate, "yyyy-MM-dd") : ""
      }`}
    >
      <Text
        color={active ? Colors.link : Colors.primary}
        style={{ paddingRight: Spacing.between }}
        type="longTitle"
      >
        {children}
      </Text>
    </Link>
  );
}

function CalendarFilter({
  initialEventType = undefined,
  replace = false,
  initialFrom = undefined,
  initialTo = undefined,
  compact = false,
  hide = [],
  eventTypes = [],
}: CalendarFilterProps) {
  let nextEventType = initialEventType || "all";
  let nextFrom = initialFrom || format(new Date(), "yyyy-MM-dd");
  let nextTo = initialTo || format(addDays(new Date(), 7), "yyyy-MM-dd");
  const router = useRouter();
  const current: "today" | "tomorrow" | "weekend" | "range" | "none" = useMemo(
    () =>
      doesMatch(router.asPath, new Date())
        ? "today"
        : doesMatch(router.asPath, addDays(new Date(), 1))
        ? "tomorrow"
        : doesMatch(
            router.asPath,
            subDays(
              isSunday(new Date()) ? new Date() : nextSunday(new Date()),
              2
            ),
            isSunday(new Date()) ? new Date() : nextSunday(new Date())
          )
        ? "weekend"
        : initialFrom
        ? "range"
        : "none",
    [router.asPath]
  );

  const now = new Date();
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: compact ? Spacing.between * 2 : Spacing.between * 3,
        }}
      >
        <DatePresetButton
          active={current === "today"}
          replace={replace}
          fromDate={now}
          eventType={nextEventType}
        >
          Heute
        </DatePresetButton>
        <DatePresetButton
          active={current === "tomorrow"}
          replace={replace}
          fromDate={addDays(now, 1)}
          eventType={nextEventType}
        >
          Morgen
        </DatePresetButton>
        <DatePresetButton
          active={current === "weekend"}
          replace={replace}
          fromDate={
            isFriday(now) || isSaturday(now) || isSunday(now)
              ? now
              : nextFriday(now)
          }
          toDate={isSunday(now) ? now : nextSunday(new Date())}
          eventType={nextEventType}
        >
          Dieses Wochenende
        </DatePresetButton>
        <DateRangePicker
          fromDate={initialFrom && parse(initialFrom, "yyyy-MM-dd", new Date())}
          toDate={initialTo && parse(initialTo, "yyyy-MM-dd", new Date())}
          color={current === "range" ? Colors.link : Colors.primary}
          onPicked={(from: Date, to: Date) => {
            if (replace) {
              router.replace(
                `/calendar/${nextEventType}/${format(
                  from,
                  "yyyy-MM-dd"
                )}/${format(to, "yyyy-MM-dd")}`
              );
            } else {
              router.push(
                `/calendar/${nextEventType}/${format(
                  from,
                  "yyyy-MM-dd"
                )}/${format(to, "yyyy-MM-dd")}`
              );
            }
          }}
        />
      </View>
      <CategoryMenu
        eventTypes={eventTypes}
        hideCategories={hide}
        current={initialEventType}
        onHover={(value) =>
          router.prefetch(`/calendar/${value}/${nextFrom}/${nextTo}`)
        }
        onPicked={(value) => {
          if (replace) {
            router.replace(`/calendar/${value}/${nextFrom}/${nextTo}`);
          } else {
            router.push(`/calendar/${value}/${nextFrom}/${nextTo}`);
          }
        }}
      />
    </View>
  );
}

export { CalendarFilter };
