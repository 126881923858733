import React from "react";
import { View } from "react-native";
import { Link, ResourceType } from "shared/components/Link";
import { Text } from "shared/components/Text";
import { Spacing, Colors } from "shared/utils/theme";
import { Icon } from "shared/components/Icon";
import { HotButton } from "shared/components/HotButton";
import { SignedIn, SignedOut, useAuth } from "shared/utils/auth";
import { Touchable } from "shared/components/Touchable";
import { useState } from "react";
import { useRouter } from "next/router";
import { useProfile } from "shared/utils/profile";

function StandaloneHeader() {
  return null;
}

function Header({ minimal }) {
  return (
    <>
      <View style={{ width: "100%" }} dataSet={{ hackMobileHide: true }}>
        <DesktopHeader minimal={minimal} />
      </View>
      <View style={{ width: "100vw" }} dataSet={{ hackMobileOnly: true }}>
        <MobileHeader minimal={minimal} />
      </View>
    </>
  );
}

function MobileHeader({ minimal }) {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <View>
      <View
        style={{
          paddingHorizontal: Spacing.between,
          paddingTop: Spacing.between,
          paddingBottom: Spacing.between * 2,
          marginBottom: Spacing.between,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Link
          style={{
            flexDirection: "row",
            alignItems: "flex-end",
          }}
          resourceType="nextLink"
          resourceId="/"
        >
          <Icon
            name="k-balloon"
            color={Colors.primary}
            size={44}
            style={{ marginRight: 4 }}
          />
        </Link>
        <View style={{ flex: 1 }} />
        <Touchable onPress={() => setShowMenu((s) => !s)}>
          <Icon name="f-menu" color={Colors.primary} size={28} />
        </Touchable>
      </View>
      {showMenu && (
        <View
          style={{
            marginBottom: Spacing.between,
            marginHorizontal: Spacing.between,
          }}
        >
          <ProfileHeader />
          <MenuContent />
        </View>
      )}
    </View>
  );
}

function MenuLink({
  resourceType,
  resourceId,
  active = false,
  children,
}: {
  resourceType: ResourceType;
  resourceId?: string;
  active?: boolean;
  children?: any;
}) {
  return (
    <Link
      style={{ margin: Spacing.between, justifyContent: "center" }}
      resourceType={resourceType}
      resourceId={resourceId}
    >
      <Text
        style={{ textAlign: "center" }}
        color={active ? Colors.link : Colors.primary}
        type="longTitle"
      >
        {children}
      </Text>
    </Link>
  );
}

function MenuContent() {
  const router = useRouter();
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: Spacing.between * 2,
      }}
      dataSet={{
        hackColumnRow: true,
      }}
    >
      <MenuLink
        active={router.asPath === "/"}
        resourceType="nextLink"
        resourceId="/"
      >
        Home
      </MenuLink>
      <MenuLink
        active={router.pathname.includes("/calendar/")}
        resourceType="nextLink"
        resourceId="/calendar/all"
      >
        Kalender
      </MenuLink>
      <MenuLink
        active={
          router.asPath.includes("/profiles") ||
          router.asPath.includes("/profileList")
        }
        resourceType="nextLink"
        resourceId="/profiles"
      >
        Profile
      </MenuLink>
      <MenuLink
        active={router.pathname.includes("/werben/")}
        resourceType="nextLink"
        resourceId="/werben"
      >
        Werben
      </MenuLink>
    </View>
  );
}

function ProfileSection() {
  const { logout } = useAuth();
  const { slug, created } = useProfile();

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginHorizontal: Spacing.betweenHalf,
        marginVertical: Spacing.between,
      }}
    >
      <Touchable onPress={logout}>
        <Text type="button">Abmelden</Text>
      </Touchable>
      {created ? (
        <>
          <View
            style={{
              marginLeft: Spacing.between,
              marginRight: Spacing.between,
            }}
          >
            <HotButton
              disabled={!slug}
              gradient="secondary"
              resourceType="profile"
              resourceId={slug}
            >
              Dein Profil
            </HotButton>
          </View>
          <HotButton
            gradient="b"
            resourceType="nextLink"
            resourceId="/e/create"
          >
            + Neues Event
          </HotButton>
        </>
      ) : (
        <View
          style={{
            marginLeft: Spacing.between,
            marginRight: Spacing.between,
          }}
        >
          <HotButton
            gradient="a"
            resourceType="nextLink"
            resourceId="/auth/create"
          >
            Profil ausfüllen
          </HotButton>
        </View>
      )}
    </View>
  );
}

function ProfileHeader({ minimal }) {
  return (
    <View>
      <SignedOut>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: Spacing.betweenHalf,
            marginVertical: Spacing.between,
          }}
        >
          {!minimal && (
            <MenuLink resourceType="nextLink" resourceId="/auth/login">
              Log In
            </MenuLink>
          )}
          {!minimal && (
            <View style={{ margin: Spacing.between }}>
              <HotButton
                large
                resourceType="nextLink"
                resourceId={"/for/venues"}
              >
                Bei KULTA veröffentlichen
              </HotButton>
            </View>
          )}
        </View>
      </SignedOut>
      <SignedIn>
        <ProfileSection />
      </SignedIn>
    </View>
  );
}

function DesktopHeader({ minimal }) {
  return (
    <View
      style={{
        height: 148,
        justifyContent: "center",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          maxWidth: "100%",
          flex: 1,
          alignItems: "center",
          padding: Spacing.between * 2,
        }}
      >
        <Link
          style={{
            flexDirection: "row",
            alignItems: "flex-end",
          }}
          resourceType="nextLink"
          resourceId="/"
        >
          <Icon
            name="k-balloon"
            color={Colors.primary}
            size={40}
            style={{ marginRight: 4 }}
          />
          <Text
            dataSet={{ hackTabletHide: true }}
            color={Colors.primary}
            type="largeTitle"
          >
            KULTA
          </Text>
        </Link>
        {!minimal && <MenuContent />}
        <View style={{ flex: 1 }} />
        <ProfileHeader minimal={minimal} />
      </View>
    </View>
  );
}

export { Header, StandaloneHeader };
