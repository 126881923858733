import React from "react";
import Head from "next/head";
import { EventScreenOccurrence_occurrence } from "mobile/src/screens/EventScreen/_gql/EventScreenOccurrence";
import { ProfileRowFragment } from "shared/components/ProfileRow/_gql/ProfileRowFragment";
import { formatDateTimeForCard } from "shared/utils/formatDate";
import { makeWebURI } from "shared/components/Image/shared";

function EventSharingMeta({
  occurrence,
}: {
  occurrence: EventScreenOccurrence_occurrence;
}) {
  return (
    <Head>
      <meta
        property="og:title"
        content={`${occurrence.event.name} @ ${occurrence.event.profile.name}`}
      />
      <meta
        property="og:description"
        content={`${formatDateTimeForCard(new Date(occurrence.startDate))}`}
      />
      <meta name="description" content={occurrence.event.description} />
      <meta
        property="og:image"
        content={makeWebURI(occurrence.event.coverImage_handle, 1200, 628)}
      />
    </Head>
  );
}

function ProfileSharingMeta({ profile }: { profile: ProfileRowFragment }) {
  return (
    <Head>
      <meta property="og:title" content={`${profile.name} - KULTA`} />
      <meta name="description" content={profile.tagline} />
      <meta property="og:description" content={profile.tagline} />
      <meta
        property="og:image"
        content={makeWebURI(profile.image_handle, 1200, 628, {
          faces: false,
          round: true,
        })}
      />
    </Head>
  );
}

function DefaultSharingMeta({
  title = "KULTA",
  description = "Entdecke großartige Veranstaltungen in deiner Nähe",
  imageURL = "https://kulta.vercel.app/og.png",
}) {
  return (
    <Head>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageURL} />
    </Head>
  );
}

export { EventSharingMeta, ProfileSharingMeta, DefaultSharingMeta };
