import { useQuery } from "urql";
import gql from "graphql-tag";
import { useMemo } from "react";
import { useAuth } from "shared/utils/auth";
import Router from "next/router";

function useProfile() {
  let created = false;
  const { signedIn } = useAuth();
  const [{ data, error, fetching }] = useQuery({
    query: gql`
      query ProfileHeaderQuery {
        profiles {
          id
          slug
          main_place_id
        }
      }
    `,
  });
  if (!signedIn) {
    throw new Error("Don't useProfile in non-signed-area");
  }

  if (error) {
    throw new Error(error.message);
  }
  const slug = useMemo(() => {
    if (data) {
      return data?.profiles[0]?.slug;
    } else {
      return null;
    }
  }, [data]);

  const main_place_id = useMemo(() => {
    if (data) {
      return data?.profiles[0]?.main_place_id;
    } else {
      return null;
    }
  }, [data]);

  if (slug) {
    created = true;
  }

  return { slug, created, main_place_id, fetching };
}

export { useProfile };
