import React from "react";
import { View } from "react-native";
import { Touchable } from "shared/components/Touchable";
import { Text } from "shared/components/Text";
import { LinearGradient } from "expo-linear-gradient";
import { Link } from "shared/components/Link";
import { Radius, Spacing, Gradients, Colors } from "shared/utils/theme";

const gradients = {
  a: Gradients.other.revolut,
  b: Gradients.ui[0],
  c: Gradients.apple[6],
  secondary: [Colors.transparent.primary[2], Colors.transparent.primary[1]],
  disabled: [Colors.transparent.primary[4], Colors.transparent.primary[3]],
};

function HotButton({
  resourceType = undefined,
  resourceId = undefined,
  onPress = () => {},
  children,
  gradient = "a",
  large = false,
  small = false,
  disabled = false,
  ...rest
}) {
  const Comp = resourceType ? Link : Touchable;
  return (
    <Comp
      disabled={disabled}
      onPress={onPress}
      resourceType={resourceType}
      resourceId={resourceId}
      {...rest}
    >
      <LinearGradient
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
        colors={disabled ? gradients.disabled : gradients[gradient]}
        style={{
          padding: large
            ? Spacing.between + 8
            : small
            ? Spacing.between - 4
            : Spacing.between,
          borderRadius: small ? Radius.medium : Radius.round,
          shadowColor: disabled ? "transparent" : gradients[gradient][1],
          shadowOpacity: 0.4,
          shadowRadius: 16,
          shadowOffset: { width: 2, height: 4 },
          alignItems: "center",
        }}
      >
        <Text
          color={gradient === "secondary" ? Colors.primary : "white"}
          type={large ? "longTitle" : small ? "button" : "smallTitle"}
        >
          {children}
        </Text>
      </LinearGradient>
    </Comp>
  );
}

export { HotButton };
