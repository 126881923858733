import React from "react";
import { View } from "react-native";
import { Text } from "shared/components/Text";
import { Spacing, Colors } from "shared/utils/theme";
import { Link } from "shared/components/Link";
import { Icon } from "shared/components/Icon";

function FooterLink({ children, resourceType, resourceId, modal = false }) {
  return (
    <Link resourceType={resourceType} resourceId={resourceId} modal={modal}>
      <Text type="button" color={Colors.secondary}>
        {children}
      </Text>
    </Link>
  );
}

function Legal() {
  return (
    <View
      style={{ marginVertical: Spacing.between }}
      dataSet={{ hackTextCenterLeft: true }}
    >
      <Text style={{ marginBottom: 8 }} type="longTitle">
        Legal
      </Text>
      <FooterLink resourceType="page" resourceId="impressum">
        Impressum
      </FooterLink>
      <FooterLink resourceType="page" resourceId="kontakt">
        Kontakt
      </FooterLink>
      <FooterLink resourceType="page" resourceId="privacy">
        Datenschutz
      </FooterLink>
    </View>
  );
}

function Support() {
  return (
    <View
      dataSet={{ hackMobileHide: true }}
      style={{ marginVertical: Spacing.between }}
    >
      <Text type="longTitle">Unterstützt durch</Text>
      <View style={{ marginVertical: Spacing.between, flexDirection: "row" }}>
        <Link
          resourceType="url"
          resourceId="https://www.digitalhub.ms/startup-fellowship"
          modal={true}
          style={{ marginVertical: Spacing.between }}
        >
          <img
            style={{ height: 48, width: "auto", objectFit: "contain" }}
            src="/support-hub.png"
            alt="Digital Hub"
          />
        </Link>
        <Link
          resourceType="url"
          resourceId="https://www.gruenderstipendium.nrw/"
          modal={true}
          style={{ margin: Spacing.between }}
        >
          <picture>
            <source
              srcSet="/nrw-light.png"
              media="(prefers-color-scheme: dark)"
            />
            <img
              style={{ height: 48, width: "auto", objectFit: "contain" }}
              src="/nrw-dark.png"
              alt="NRW"
            />
          </picture>
        </Link>
      </View>
    </View>
  );
}
function Social() {
  return (
    <View
      style={{ marginVertical: Spacing.between, alignItems: "center" }}
      dataSet={{ hackTextCenterLeft: true }}
    >
      <Text type="longTitle">Folge uns</Text>
      <View style={{ marginTop: Spacing.between }}>
        <Link
          resourceType="url"
          resourceId="https://www.instagram.com/kulta_app/"
          modal={true}
          style={{ marginLeft: -48 }}
        >
          <Icon name="f-instagram" size={40} color={Colors.primary} />
        </Link>
      </View>
    </View>
  );
}

function Footer() {
  return (
    <View
      dataSet={{ hackColumnRow: true }}
      style={{
        justifyContent: "space-between",
        width: "100%",
        padding: Spacing.between * 6,
      }}
    >
      <Support />
      <Social />
      <Legal />
    </View>
  );
}

function StandaloneFooterLink({
  children,
  resourceType,
  resourceId,
  modal = false,
}) {
  return (
    <Link resourceType={resourceType} resourceId={resourceId} modal={modal}>
      <Text
        style={{ paddingHorizontal: 8 }}
        type="captionStrong"
        color={Colors.secondary}
      >
        {children}
      </Text>
    </Link>
  );
}

function StandaloneFooter() {
  return (
    <View
      dataSet={{ hackColumnRow: true }}
      style={{
        width: "100%",
        padding: Spacing.between * 2,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          opacity: 0,
          justifyContent: "center",
          marginHorizontal: Spacing.between,
        }}
        dataSet={{ hackMobileHide: true }}
      >
        <Text type="captionStrong" style={{ marginRight: 4 }}>
          Powered by
        </Text>
        <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
          <Icon
            name="k-balloon"
            color={Colors.primary}
            size={22}
            style={{ marginRight: 4 }}
          />
          <Text color={Colors.primary} type="longTitle">
            KULTA
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          padding: Spacing.between * 2,
        }}
      >
        <StandaloneFooterLink resourceType="page" resourceId="impressum">
          Impressum
        </StandaloneFooterLink>
        <StandaloneFooterLink resourceType="page" resourceId="privacy">
          Datenschutz
        </StandaloneFooterLink>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginHorizontal: Spacing.between,
        }}
      >
        <Text type="captionStrong" style={{ marginRight: 4 }}>
          Powered by
        </Text>
        <Link
          style={{ flexDirection: "row", alignItems: "flex-end" }}
          resourceType="url"
          resourceId="https://kulta.app"
        >
          <Icon
            name="k-balloon"
            color={Colors.primary}
            size={22}
            style={{ marginRight: 4 }}
          />
          <Text color={Colors.primary} type="longTitle">
            KULTA
          </Text>
        </Link>
      </View>
    </View>
  );
}

export { Footer, StandaloneFooter };
